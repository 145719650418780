import { api } from "api";
import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import { FaCheck } from "react-icons/fa";
import { Logo } from "../../assets/icons";
import { routes, utils } from "common";
import "./_verification.scss";

const VerificationPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const verifyAccount = async () => {
    try {
      const res = await api.verifyAccount(email, token);
      setSuccess(true);
    } catch (error) {
      setError(error);
      utils.showErrorToast(error);
    }
  };

  useEffect(() => {
    verifyAccount();
  }, []);

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg py-0">
          <div className="container-fluid d-flex justify-content-between">
            <Link className="navbar-brand" to={routes.HOME}>
              <img src={Logo} alt={"logo"} />
            </Link>
          </div>
        </nav>
      </header>
      <div className="veirficationContainer">
        {error ? (
          <div>
            <h2 className="text-2xl font-semibold mb-4 text-center">
              {error ? `${error?.response?.data?.error}` : "Something Went Wrong!"}
            </h2>
          </div>
        ) : success ? (
          <div className="successContainer">
            <div className="checkBox">
              <FaCheck size={50} color={"white"} />
            </div>

            {/* Text */}
            <div>
              <h2 className="text-2xl font-semibold mb-4 text-center">
                Thank you for your verification.
              </h2>
              <p className="text-gray-500 text-center max-w-md px-4">
                You can now sign in to your{" "}
                <strong className="text">
                  Fatafat Vendor Account
                </strong>{" "}
                and start taking orders!
              </p>
            </div>
          </div>
        ) : (
          <>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <h2>Verifying...</h2>
          </>
        )}
      </div>
    </>
  );
};

export default VerificationPage;
