export const routes = {
  HOME: '/',
  VENDORS: '/vendors/:id',
  VENDOR_DETAIL: '/vendor-detail/:id',
  ORDER: '/order/:token',
  ORDER_COMPLETE: '/order/complete',
  WEB_ORDER: '/web-order/:token',
  PRIVACY: '/privacy',
  VERIFICATION_SUCCESS: '/signup/success',
};
