import { apiUtils, endpoints } from 'api';

export const api = {
  login: async (data) => {
    return await apiUtils.post(endpoints.LOGIN, data, false);
  },
  getAreas: async (params) => {
    return await apiUtils.get(endpoints.AREAS, params, false);
  },
  getCategories: async (params) => {
    return await apiUtils.get(endpoints.CATEGORIES, params, false);
  },
  getShops: async ({areaId, params}) => {
    return await apiUtils.get(endpoints.SHOPS(areaId), params);
  },
  getShopsFromStart: async ({areaId, params}) => {
    params['page'] = 1;
    return await apiUtils.get(endpoints.SHOPS(areaId), params);
  },
  getShopSuggestions: async ({areaId, params}) => {
    return await apiUtils.get(endpoints.SHOP_SUGGESTIONS(areaId), params);
  },
  getOrderShops: async ({token, areaId}) => {
    return await apiUtils.get(endpoints.ALL_SHOPS(areaId), {}, true, token);
  },
  sendOtp: async (data) => {
    return await apiUtils.post(endpoints.SEND_OTP, data, false);
  },
  checkOtp: async (data) => {
    return await apiUtils.post(endpoints.CHECK_OTP, data, false, null, false);
  },
  verifyOtp: async (data) => {
    return await apiUtils.post(endpoints.VERIFY_OTP, data, false);
  },
  getShopDetail: async (shopId) => {
    return await apiUtils.get(endpoints.SHOP_DETAIL(shopId), {}, false);
  },
  createCustomerOrder: async () => {
    return await apiUtils.post(endpoints.CUSTOMER_ORDER_CREATE);
  },
  getCustomerAddresses: async (params = {}, token = null) => {
    return await apiUtils.get(endpoints.CUSTOMER_ADDRESSES(), params, true, token);
  },
  getCustomerDetail: async (token) => {
    return await apiUtils.get(endpoints.CUSTOMER_DETAIL, {}, true, token);
  },
  createCustomerAddresses: async (token, data) => {
    return await apiUtils.post(endpoints.CUSTOMER_ADDRESSES(), data, true, token);
  },
  updateCustomerAddress: async (addressId, data) => {
    return await apiUtils.patch(endpoints.CUSTOMER_ADDRESSES(addressId), data, true);
  },
  deleteCustomerAddress: async (addressId) => {
    return await apiUtils.delete(endpoints.CUSTOMER_ADDRESSES(addressId), null, true);
  },
  createOrder: async (token, data) => {
    return await apiUtils.post(endpoints.CREATE_ORDER, data, true, token);
  },
  getCustomerReviews: async ({shopId, params = {}}) => {
    return await apiUtils.get(endpoints.CUSTOMER_REVIEWS(shopId), params, false);
  },
  createCustomerReview: async (shopId, data) => {
    return await apiUtils.post(endpoints.CUSTOMER_REVIEWS(shopId), data, true);
  },
  getCustomerReviewDetail: async ({shopId}) => {
    return await apiUtils.get(endpoints.REVIEW_DETAIL(shopId), null, true);
  },
  updateCustomerReview: async (shopId, data) => {
    return await apiUtils.patch(endpoints.CUSTOMER_REVIEWS(shopId), data, true);
  },
  getAddress: async ({token, params = {}}) => {
    return await apiUtils.get(endpoints.ADDRESS, params, true, token, false);
  },
  getSectors: async () => {
    return await apiUtils.get(endpoints.SECTORS, null, false);
  },
  getBlocks: async ({params = {}}) => {
    return await apiUtils.get(endpoints.BLOCKS, params, false);
  },
  transcribeAudio: async (data, token) => {
    return await apiUtils.post(endpoints.TRANSCRIPTION, data, true, token);
  },
  getRawTranscription: async (token, audioId) => {
    return await apiUtils.get(endpoints.RAW_TRANSCRIPTION(audioId), null, true, token);
  },
  getProcessedTranscription: async (token, audioId) => {
    return await apiUtils.get(endpoints.PROCESSED_TRANSCRIPTION(audioId), null, true, token);
  },
  createContactRequest: async (data) => {
    return await apiUtils.post(endpoints.CONTACT_REQUEST, data, false, null, false);
  },
  verifyAccount: async (email, token) => {
    return await apiUtils.get(endpoints.ACCOUNT_VERIFICATION(email, token), null, false);
  },
};
